<template>
  <q-dialog v-model="dialogCampaign">
    <q-card style="width: 80vw; max-width: 80vw" flat class="full-height">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Campanha </q-toolbar-title>
        <q-btn flat icon="mdi-close" @click="onClose()" round dense />
      </q-toolbar>
      <q-card class="q-pa-sm">
        <q-card-section class="row q-col-gutter-md">
          <div class="col-md-6 col-xl-6 col-xs-12">
            <q-card flat bordered class="fit">
              <q-img
                v-if="infoCampaign.logo_url"
                contain
                spinner-color="primary"
                spinner-size="82px"
                style="height: 200px"
                :src="infoCampaign.logo_url"
              >
                <template v-slot:error>
                  <div class="absolute-full flex flex-center text-grey bg-grey-3">
                    <q-icon name="mdi-image-off-outline" size="128px" />
                  </div>
                </template>
                <div class="absolute-bottom text-center">
                  {{ infoCampaign.name }}
                </div>
              </q-img>

              <div v-else class="absolute-full flex flex-center">
                <q-avatar size="100px" font-size="52px" color="primary" rounded text-color="white">{{
                  infoCampaign.name.toUpperCase()[0]
                }}</q-avatar>
                <div class="absolute-bottom text-center text-white bg-grey-7" style="height: 16%; font-size: 140%">
                  {{ infoCampaign.name }}
                </div>
              </div>
            </q-card>
          </div>
          <div class="col-md-6 col-xl-6 col-xs-12">
            <q-card bordered flat class="fit">
              <q-card-section>
                <div class="text-h5 text-bold">
                  {{ infoCampaign.name }}
                </div>
                <q-item>
                  <q-item-section>
                    <q-item-label>URL</q-item-label>
                    <q-item-label caption>{{ infoCampaign.url }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label>Descrição</q-item-label>
                    <q-item-label caption>{{ infoCampaign.campaign_description }} </q-item-label>
                  </q-item-section>
                </q-item>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-subtitle2 col">até</div>
                <div class="text-h4 text-bold col">
                  <div class="row">
                    <div class="col-10">
                      {{
                        campaignSelect.comission_format == 'value'
                          ? parseMoney(campaignSelect.affiliate_comission_value)
                          : campaignSelect.affiliate_comission_value + ' %'
                      }}
                    </div>
                    <div class="col-2">
                      <q-btn
                        padding="xs"
                        color="primary"
                        unelevated
                        icon="eva-plus-circle-outline"
                        @click="getCommissionCard"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-subtitle2">de comissão</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-h4 text-bold text-uppercase col justify-center items-center" style="height: 75%">
                  {{ campaignSelect.comission_type }}
                </div>
                <div class="text-subtitle2">Tipo de comissão</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-h4 text-bold col justify-center items-center" style="height: 75%">
                  {{ campaignSelect.payment_period }} dias
                </div>
                <div class="text-subtitle2">Período de Pagamento</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-h4 text-bold col justify-center items-center" style="height: 75%">
                  {{ campaignSelect.validation_period }} dias
                </div>
                <div class="text-subtitle2">Período de Validação</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-h4 text-bold col justify-center items-center" style="height: 75%">
                  {{ infoCampaign.cookie_time }}
                  {{ $t(infoCampaign.cookie_time_metric + '_metric') }}
                </div>
                <div class="text-subtitle2 text-center">Tempo de cookie</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-subtitle1 text-bold col justify-center items-center" style="height: 75%">
                  {{ defineCampaignAttributionsText() }}
                </div>
                <div class="text-subtitle2 text-center">Atribuição</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-subtitle1 text-bold col justify-center items-center" style="height: 75%">
                  {{ defineCampaignRestrictionsText() }}
                </div>
                <div class="text-subtitle2">Restrições</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 justify-center flex items-center fit">
              <q-card-section class="text-center q-pt-lg column justify-between" style="height: 100%">
                <div class="text-subtitle1 text-bold col justify-center items-center" style="height: 75%">
                  {{ defineCampaignVersionText() }}
                </div>
                <div class="text-subtitle2">Versão</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-3 col-xl-3 col-xs-12">
            <q-card flat bordered class="bg-grey-3 justify-center flex items-center fit">
              <q-card-section class="text-center q-pt-lg">
                <div class="text-h4 text-bold">
                  {{ infoCampaign.deeplink ? 'Permitido' : 'Não permitido' }}
                </div>
                <div class="text-subtitle2">Deeplink</div>
              </q-card-section>
            </q-card>
          </div>
        </q-card-section>
        <q-card-section class="row items-start q-gutter-md q-pt-none" v-if="creatives.length > 0">
          <div v-for="(item, index) in creatives" :key="index">
            <q-card flat bordered :style="`width: ${($q.screen.width - ($q.screen.width / 10) * 2) / 5 - 23}px`">
              <q-card-section class="text-center">
                <div class="text-subtitle2">
                  {{ item.description }}
                </div>
                <q-icon :name="getIconCreative(item.name)" size="80px" color="grey" />
              </q-card-section>
            </q-card>
          </div>
        </q-card-section>
        <q-card-section class="row q-col-gutter-md q-pt-none" v-else>
          <div class="col-12">
            <q-card flat bordered>
              <q-card-section class="text-center q-py-lg">
                <div class="text-subtitle2">Não há criativos para esta campanha.</div>
              </q-card-section>
            </q-card>
          </div>
        </q-card-section>
      </q-card>

      <q-tabs v-model="tab" class="text-teal">
        <q-tab label="Cadastrar Canal" name="registerChannel" />
        <q-tab label="Canais Cadastrados" name="registeredChannels" />
        <q-tab label="Relatórios" name="reports" />
        <!-- <q-tab label="Regras da Campanha" name="campaignRules" /> -->
      </q-tabs>

      <q-separator />
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="registerChannel">
          <q-card-section class="row q-col-gutter-md">
            <div class="col-4">
              <select-request
                :v-model="channel"
                outlined
                :endpoint="`redirect?app=advertiser&path=/api/v2/get/campaign/nonrelated/affiliate/filter/${campaignSelect.campaign_id}`"
                option-label="name"
                option-value="id"
                label="Selecione o canal"
                clearable
                @update="(value) => (channel = value === null ? null : value.id)"
              />
            </div>
            <div class="col-4 flex-break">
              <q-btn
                :disabled="!channel"
                color="positive"
                label="Respeito as regras e solicito participação"
                no-caps
                unelevated
                class="q-px-md q-py-sm"
                @click="submitSiteToCampaign"
              />
              <q-icon name="mdi-help-circle" class="cursor-pointer q-mx-md" size="2rem" color="primary" v-if="!channel">
                <q-tooltip content-class="text-body2">
                  É necessário que você tenha pelo menos 1 canal aprovado na plataforma para pedir participação na
                  campanha
                </q-tooltip>
              </q-icon>
            </div>
          </q-card-section>
        </q-tab-panel>
        <q-tab-panel name="registeredChannels">
          <q-table
            flat
            title="Canais Cadastrados"
            bordered
            :data="sitesRelatedList"
            row-key="id"
            :columns="columns"
            :pagination="pagination"
            no-data-label="Sem sites cadastrados nessa campanha"
          >
            <template v-slot:header="props">
              <q-tr :props="props">
                <q-th v-for="col in props.cols" :key="col.name" :props="props">
                  {{ col.label }}
                </q-th>
                <q-th auto-width> Comissão </q-th>
              </q-tr>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                  :class="props.expand ? 'bg-primary text-white' : ''"
                >
                  {{ col.value }}
                </q-td>
                <q-td
                  v-if="sitesRelatedList.find((item) => item.commissions.length > 0)"
                  auto-width
                  class="text-center"
                  :class="props.expand ? 'bg-primary text-white' : ''"
                >
                  <q-btn
                    size="md"
                    color="accent"
                    round
                    unelevated
                    dense
                    @click="props.expand = !props.expand"
                    :icon="props.expand ? 'remove' : 'add'"
                  >
                    <q-badge color="positive" floating>{{ props.row.commissions.length }}</q-badge>
                  </q-btn>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props" class="q-pa-none">
                <q-td colspan="100%" class="q-pa-none">
                  <q-table
                    :columns="commissionsColumns"
                    :data="props.row.commissions"
                    row-key="commissions"
                    flat
                    hide-pagination
                    hide-bottom
                    bordered
                    table-header-class="text-weight-bold "
                    color="primary"
                    card-class="bg-blue-1"
                  >
                    <template v-slot:body-cell-special_commission="props">
                      <q-td :props="props">
                        <div>
                          <q-badge color="primary" rounded :label="props.value" />
                        </div>
                      </q-td>
                    </template>
                  </q-table>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-tab-panel>
        <q-tab-panel name="reports">
          <q-btn
            color="positive"
            label="Visualizar minhas transações"
            no-caps
            unelevated
            to="/affiliate/reports/conversion"
            class="q-px-md"
          />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>

    <q-dialog v-model="commissionsExposed">
      <q-card style="width: 700px; max-width: 80vw">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title> Comissões </q-toolbar-title>
          <q-btn flat round dense icon="mdi-close" v-close-popup />
        </q-toolbar>
        <q-card-section class="q-pt-none">
          <q-table
            flat
            ref="tableCommissionsExposed"
            :data="commissionsExposedData"
            :columns="columnsExposedColumns"
            hide-pagination
            hide-bottom
          >
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>

<script>
import HeaderMyCampaign from '@/components/campaign/HeaderMyCampaign.vue';
import CampaignMixin from '../../mixins/CampaignMixin';
import AffiliateService from '../../services/AffiliateService';
import ExtraServices from '../../services/ExtraServices';
import AdvertiserService from '../../services/AdvertiserService';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';

export default {
  name: 'FormMyCampaign',
  components: { SelectRequest },
  mixins: [CampaignMixin, AffiliateService, HeaderMyCampaign, ExtraServices, AdvertiserService],
  data() {
    return {
      commissionsExposed: false,
      commissionsExposedData: null,
      commissionsColumns: [
        {
          name: 'category',
          label: 'Categoria',
          align: 'left',
          field: 'category'
        },
        {
          name: 'type',
          label: 'Tipo',
          align: 'left',
          field: 'type'
        },
        {
          name: 'special_commission',
          label: 'Comissão',
          align: 'center',
          field: (row) => (row.special_commission != '' ? row.special_commission : row.regular_commission),
          format: (val, row) => (row.format === 'percentage' ? `${val} %` : `${this.parseMoney(val)}`)
        }
      ],
      columnsExposedColumns: [
        {
          name: 'comission_category',
          label: 'Categoria',
          align: 'left',
          field: 'comission_category'
        },
        {
          name: 'affiliate_comission_value',
          label: 'Valor',
          align: 'left',
          field: 'affiliate_comission_value'
        }

        // {
        //   name: "comission_special",
        //   label: "Comissão especial",
        //   align: "left",
        //   field: "comission_special",
        // },
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      },
      channel: null,
      tab: 'registerChannel',
      fullWidth: false,
      infoCampaign: {
        contract: 'nao'
      },
      creatives: [],
      columns: [
        {
          name: 'dissemination_channel',
          label: 'Canal de Divulgação',
          align: 'left',
          field: 'name'
        },
        {
          name: 'category',
          label: 'Categoria',
          align: 'left',
          field: 'category'
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: (row) => this.formatStatus(row.campaign_site_status)
        }
      ],
      sitePreview: {
        url_preview: {
          cover: null,
          title: null
        }
      },
      sitesRelatedList: []
    };
  },
  methods: {
    async getCommissionCard() {
      this.onLoading(true);

      try {
        const { data, status } = await this.listCampaignsCommissionsByType(this.campaignSelect.campaign_id);

        if (status === 200) {
          console.log(data);
          this.commissionsExposedData = data.map((item) => ({
            ...item,
            affiliate_comission_value: ` ${
              item.comission_format === 'value'
                ? this.parseMoney(parseFloat(item.affiliate_comission_value))
                : item.affiliate_comission_value + '%'
            } `,
            comission_special: `${
              item.comission_format === 'value'
                ? this.parseMoney(parseFloat(item.comission_special))
                : item.comission_special + '%'
            } `
          }));

          this.commissionsExposed = !this.commissionsExposed;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onClose() {
      this.ActionSetDialogCampaign(false);
      this.ActionSetCampaignSelect({});
    },

    getIconCreative(name) {
      switch (name) {
        case 'hyperlink':
          return 'mdi-file-document';
        case 'coupon':
          return 'mdi-ticket-percent';
        case 'deal':
          return 'mdi-currency-usd';
        case 'emailmkt':
          return 'mdi-email';
        case 'image':
          return 'mdi-file-image-outline';
        default:
          return null;
      }
    },

    formatStatus(value) {
      switch (value) {
        case 0:
          return 'Pendente';
        case 1:
          return 'Ativado';
        case 2:
          return 'Pausado';
        case 3:
          return 'Não Aprovado';
        case 4:
          return 'Removido';
        case 5:
          return 'Não Solicitado';
        case 6:
          return 'Expirado';
      }
    },

    async getInfoCampaign() {
      this.onLoading(true);

      await this.getCreatives();

      try {
        const { data, status } = await this.$http.get(
          //"v2/redirect?app=advertiser&path=/api/get/campaign/details/" +
          'redirect?app=ADVERTISER&path=/api/get/campaign/rules/' + this.campaignSelect.campaign_id
        );
        if (status === 200) {
          this.infoCampaign = data;

          //await this.getPreview();

          await this.campaignSitesRelated();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    // async getPreview() {
    //   try {
    //     const { data, status } = await this.getLinkPreview(
    //       this.infoCampaign.url
    //     );
    //     console.log(data);
    //     if (status === 200) {
    //       this.sitePreview = data;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    async getCreatives() {
      try {
        const { data, status } = await this.$http.get(
          'v2/redirect?app=CREATIVE&path=/api/get/creative_type/campaign/' + this.campaignSelect.campaign_id
        );
        if (status === 200) {
          this.creatives = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async campaignSitesRelated() {
      try {
        const { data, status } = await this.getSitesCampaignRelatedByAffiliate(this.campaignSelect.campaign_id);
        if (status === 200) {
          this.sitesRelatedList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    defineCampaignRestrictionsText() {
      var responseArr = [];
      responseArr.push(this.infoCampaign.restriction_site_under ? 'Site Under' : null);
      responseArr.push(this.infoCampaign.restriction_word_purchase ? 'Compra de palavras' : null);
      responseArr.push(this.infoCampaign.restriction_retargeting ? 'Retargeting' : null);
      responseArr.push(this.infoCampaign.restriction_email_marketing ? 'E-mail marketing' : null);

      var responseStr = responseArr.filter((val) => val != null).join(', ');

      return responseStr ? responseStr : 'Não há';
    },

    defineCampaignAttributionsText() {
      var responseArr = [];
      responseArr.push(this.infoCampaign.last_click ? 'Last click' : null);
      responseArr.push(this.infoCampaign.organic ? 'Orgânico' : null);
      responseArr.push(this.infoCampaign.assisted ? 'Assistido' : null);
      responseArr.push(this.infoCampaign.direct ? 'Direto' : null);
      return responseArr.filter((val) => val != null).join(', ');
    },

    defineCampaignVersionText() {
      var responseArr = [];
      responseArr.push(this.infoCampaign.mobile_responsive ? 'Responsivo' : null);
      responseArr.push(this.infoCampaign.mobile_msite ? 'Mobile' : null);

      var resString = responseArr.filter((val) => val != null).join(', ');

      return resString ? resString : '-';
    },

    async submitSiteToCampaign() {
      this.onLoading(true);

      try {
        const { status } = await this.participateSiteInCampaign(this.campaignSelect.campaign_id, this.channel);

        if (status === 200 || status === 201) {
          await this.campaignSitesRelated();

          this.successNotify('Participação enviada para análise');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.channel = null;

        this.onLoading(false);
      }
    }
  },
  created() {
    this.getInfoCampaign();
  }
};
</script>
