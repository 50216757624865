<template>
  <div>
    <q-table
      flat
      bordered
      :grid="grid"
      :data="campaigns"
      row-key="campaign_id"
      :columns="columns"
      :pagination.sync="pagination"
      :visible-columns="visibleColumns"
      :filter="filter"
    >
      <template v-slot:body-cell-currency="props">
        <q-td :props="props">
          <q-btn
            unelevated
            round
            dense
            flat
            icon="info"
            color="primary"
            @click="ActionSetDialogCampaign({ value: props.row })"
          >
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
              Mais informações
            </q-tooltip>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-campaign_site_status="props">
        <q-td :props="props">
          <q-chip
            dense
            square
            class="bg-green-5 text-white"
            label="Aprovado"
            v-if="props.row.campaign_site_status === 1"
          />
          <q-chip
            dense
            square
            class="bg-light-blue-3 text-white"
            label="Não Solicitado"
            v-else-if="props.row.campaign_site_status === 5"
          />
          <q-chip
            dense
            square
            class="bg-yellow-7 text-white"
            label="Pendente"
            v-else-if="props.row.campaign_site_status === 0"
          />
          <q-chip
            dense
            square
            class="bg-deep-purple-5 text-white"
            label="Pausado"
            v-else-if="props.row.campaign_site_status === 2"
          />
          <q-chip
            dense
            square
            class="bg-grey-9 text-white"
            label="Removido"
            v-else-if="props.row.campaign_site_status === 4"
          />
          <q-chip
            dense
            square
            class="bg-pink-3 text-white"
            label="Não Aprovado"
            v-else-if="props.row.campaign_site_status === 3"
          />
          <q-chip
            square
            dense
            class="bg-orange text-white"
            label="-"
            v-else-if="props.row.campaign_site_status === 6"
          />
        </q-td>
      </template>
      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total: {{ reqPagination.total }}
          <q-space />
          Linhas por página:
          <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 25, 50, 100]"
            options-dense
            menu-shrink
            dense
            borderless
          />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </div>
</template>
<script>
import CampaignMixin from '../../mixins/CampaignMixin';
export default {
  name: 'ListMyCampaign',
  mixins: [CampaignMixin],
  props: {
    type: {
      type: String,
      default: 'mycampaigns'
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  data() {
    return {
      campaignsAffiliate: [],
      grid: false,
      visibleColumns: null,
      filter: null,
      columns: [
        {
          name: 'campaign_id',
          align: 'left',
          label: 'ID',
          field: 'campaign_id'
        },
        {
          name: 'campaign_name',
          align: 'left',
          label: 'Nome',
          field: 'campaign_name',
          sortable: true
        },
        {
          name: 'comission_type',
          align: 'left',
          label: 'Tipo de Comissão',
          field: 'comission_type',
          style: 'text-transform: uppercase'
        },
        {
          name: 'affiliate_comission_value',
          align: 'left',
          label: 'Comissão',
          field: 'affiliate_comission_value',
          format: (val, row) => this.formatCommission(val, row)
        },
        {
          name: 'validation_period',
          align: 'left',
          label: 'Período de Validação',
          field: 'validation_period',
          format: (val) => {
            return val ? val + ' dias' : '';
          }
        },
        {
          name: 'payment_period',
          align: 'left',
          label: 'Período de Pag',
          field: 'payment_period',
          format: (val) => {
            return val ? val + ' dias' : '';
          }
        },
        {
          name: 'category_name',
          align: 'left',
          label: 'Categoria',
          field: 'category_name'
        },
        {
          name: 'campaign_site_status',
          align: 'left',
          label: 'Status do canal',
          field: 'campaign_site_status'
        },

        {
          label: 'Ações',
          name: 'currency',
          align: 'center',
          field: 'currency'
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getMyCampaigns(`${this.currentPage}`, `${this.pagination.rowsPerPage}`);
      }
    },
    'pagination.rowsPerPage'() {
      this.getMyCampaigns(1, `${this.pagination.rowsPerPage}`);
    },

    filterCampaign: {
      handler(value) {
        this.visibleColumns = value?.site_id
          ? this.columns.map((column) => column.name)
          : this.columns.map((column) => column.name).filter((column) => column != 'campaign_site_status');
      },
      deep: true
    }
  },
  created() {
    this.visibleColumns = this.columns
      .map((column) => column.name)
      .filter((column) => column != 'campaign_site_status');
    this.ActionSetFilterCampaign();
    this.getMyCampaigns();
  },

  methods: {
    formatCommission(val, row) {
      if (val && row.comission_format == 'value') return this.parseMoney(val);
      else if (typeof row.comission_format !== 'undefined') return val + ' %';
      else return '';
    }
  }
};
</script>
